import React from "react"
import { FaCode, FaSketch, FaAndroid } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "web development",
    text: `As a Front End Developer, I am responsible for implementing visual elements visible from the computer user's vantage point within a web application. I combine the art of design with the science of programming. I am responsible for the translation of UI/UX design wireframes to actual code.`,
  },
  {
    id: 2,
    icon: <FaSketch className="service-icon" />,
    title: "web design",
    text: `Building state-of-the-art, easy to use, user-friendly websites and applications is truly a passion of mine. I am confident I would be an excellent addition to your next project. I always stay ahead of the curve and deliver exceptional work to all of my clients.`,
  },
  {
    id: 3,
    icon: <FaAndroid className="service-icon" />,
    title: "app design",
    text: `As a Graphics Designer, I am responsible for designing app logos, assets, and social media banners for my clients. It would be best to have designs that speak about your brand and convert your app visitors to your clients. I would create graphics for your needs that would be minimalist to attract your customers.`,
  },
]
